"use strict";

import angular from "angular";
import moment from "moment";

import { CertificationPeriodId, PatientId } from "../messages/ids";
import { Endpoint } from "../services/endpoint.service";
import { CertificationPeriodService } from "../services/certificationPeriodService";
import { CertificationPeriod } from "../messages/certification_period";
import { DateTimeFormatter, LocalDate } from "js-joda";

interface CertificationPeriodOption {
    id: CertificationPeriodId;
    label: string;
}

type SelectorViewDirection = "row" | "column";

interface CertificationPeriodSelectorBindings {
    hasError: boolean;
    patientId: PatientId;
    direction: SelectorViewDirection;
    selectedCertificationPeriodId: CertificationPeriodId;
    certificationPeriodLocked: boolean;
}

interface CertificationPeriodSelectorOptions extends angular.IComponentOptions {
    bindings: Record<keyof CertificationPeriodSelectorBindings, string>;
}

const dateFormatter = DateTimeFormatter.ofPattern("MM/dd/yyyy");

//! @ngInject
class CertificationPeriodSelectorCtrl implements ng.IComponentController, CertificationPeriodSelectorBindings {
    hasError = false;
    direction: SelectorViewDirection = "row";
    selectedCertificationPeriodId!: CertificationPeriodId;
    certificationPeriodLocked = false;
    patientId!: PatientId;

    private certificationPeriods: CertificationPeriod[] = [];
    private certificationPeriodOptions: CertificationPeriodOption[] = [];
    private selectedCertificationPeriodOption: CertificationPeriodOption | null = null;

    constructor(
        private $scope: ng.IScope,
        private $rootScope: ng.IRootScopeService,
        private $uibModal: ng.ui.bootstrap.IModalService,
        private certificationPeriodService: CertificationPeriodService,
        private endpoint: Endpoint,
        private toaster: toaster.IToasterService
    ) {
    }

    selectOption(option: CertificationPeriodOption) {
        this.selectedCertificationPeriodOption = option;
        this.selectedCertificationPeriodId = option.id;
    }

    openCertificationPeriodModal() {
        this.$uibModal.open({
            templateUrl: "admin/views/certification-period-modal.html",
            size: "md",
            controller: "certificationPeriodModal",
            windowClass: "modal center-center",
            resolve: {
                allCertificationPeriods: () => this.certificationPeriods,
                patientId: () => parseInt(`${this.patientId}`, 10),
                onSubmitted: () => ({ certificationPeriod }: { certificationPeriod: CertificationPeriod }) => {
                    this.initCertificationPeriod(certificationPeriod.id);
                }
            }
        });
    }

    $onInit(): void {
        this.initCertificationPeriod(this.selectedCertificationPeriodId);
    }

    initCertificationPeriod(selectedId: CertificationPeriodId | null) {
        this
            .certificationPeriodService
            .getPatientPeriods(this.patientId, {refetch: true})
            .then((certificationPeriods) => {
                this.certificationPeriods = certificationPeriods;
                this.certificationPeriodOptions = this.generateCertificationPeriodOptions(certificationPeriods);

                if (selectedId !== null) {
                    this.selectedCertificationPeriodOption = this.certificationPeriodOptions.find(c => c.id === selectedId) ?? null;
                    this.selectedCertificationPeriodId = selectedId;
                }
            })
            .catch(err => {
                this.toaster.pop(
                    "error",
                    "Oops.. Something went wrong",
                    "Failed to fetch certification periods"
                );

                return [];
            });
    }

    generateCertificationPeriodOptions(certificationPeriods: CertificationPeriod[]) {
        const generateCertificationPeriodLabel = (startDate: string, endDate: string) => {
            const from = moment(startDate).format("MM/DD/YYYY");
            const end = moment(endDate).format("MM/DD/YYYY");
            return `${from} - ${end}`;
        };

        return certificationPeriods
            .map(({ id, startDate, endDate }) => {
                const label = generateCertificationPeriodLabel(startDate, endDate);
                return { id, label };
            })
            .sort();
    }
}


export const CertificationPeriodSelector: CertificationPeriodSelectorOptions = {
    controller: CertificationPeriodSelectorCtrl,
    controllerAs: "ctrl",
    templateUrl: "admin/views/certification-period-selector.html",
    bindings: {
        hasError: "<",
        patientId: "<",
        direction: "@", // row, column`
        selectedCertificationPeriodId: "=",
        certificationPeriodLocked: "="
    }
};
