import _ from "lodash";

//! @ngInject
export function billingRateSetupModalCtrl($scope, fields, data, FormService, DatabaseApi, toaster, $rootScope, $uibModal, NgTableParams) {
    $scope.hideSaveFormButton = true;
    $scope.title = $scope.viewModeOnly ? "View rate" : "Add rate";
    $scope.fields = FormService.getFields(fields, data, $scope.viewModeOnly);
    $scope.data = data;
    $scope.form = {};
    $scope.enableEditRate = false;
    $scope.changedBillingRateCounties = new Set();

    if (!$scope.viewModeOnly) {
        prepareAssignedLocationGroups();
    }
    $scope.editRate = function () {
        $scope.enableEditRate = !$scope.enableEditRate;
    }

    $scope.onRateValueChange = function (billingRateCountyId) {
        $scope.changedBillingRateCounties.add(billingRateCountyId);
    }

    $scope.saveRate = function () {
        if ($scope.viewModeOnly) {
            let editedBillingRateCountiesArr = $scope.data.rates
                .filter(r => $scope.changedBillingRateCounties.has(r.id));

            for (const rate of editedBillingRateCountiesArr) {
                rate.rateInCents = rate.rateInCents * 100;
                rate.weekendRateInCents = (rate.weekendRateInCents ?? rate.rateInCents) * 100;
            }
            
            
            let editedbillingRateCounties = {
                billingRateCounties: editedBillingRateCountiesArr
            }

            DatabaseApi.put('agencies/' + $rootScope.agencyId + '/billing_rate_counties', editedbillingRateCounties).then(function (res) {
                $scope.changedBillingRateCounties = {};
                $scope.getRates();
                // I'm not sure why but this causes an angularJS inprog error.
                // As far as I can tell my current changes has no effect on this
                // and for some reason it doesn't happen in production.
                $scope.$close();
                toaster.pop('success', "rate counties values was saved");
            }, function (err) {
                $scope.changedBillingRateCounties = {};
                toaster.pop('error', "Something went wrong", "Could not edit rate counties values");
            });
        } else {

            $scope.form.data.$submitted = true

            if (!validateRateForm()) {
                return;
            }

            $uibModal.open({
                templateUrl: 'admin/views/update-invoice-rates-modal.html',
                size: 'md',
                controller: 'updateInvoiceRatesModalCtrl',
                windowTopClass: "boost-modal"
            }).result.then((modalResult) => {
                if (modalResult !== 'discard') {
                    $scope.updateExistingInvoiceRatesData = modalResult;
                }

                const formData = FormService.prepareFormData($scope.fields);
                const billingRateRequest = prepareBillingRateRequest(formData);

                DatabaseApi.post('agencies/' + $rootScope.agencyId + '/billing_rates', billingRateRequest).then(function (res) {
                    if (res.data.visitWithIssues && res.data.visitWithIssues.length > 0) {
                        toaster.pop('error', "Visits with issues", "The following visit IDs have issues and won't be updated \n" + res.data.visitWithIssues.join(', '), { timeOut: 20000 });
                    }
                    $scope.getRates();
                    $scope.$close();
                    toaster.pop('success', "A new rate was saved");
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Could not add a new rate");
                });

            });
        }
    };

    function validateRateForm() {
        if ($scope.form.data.$invalid) {
            return false;
        }

        for (const locationGroup in $scope.locationGroups) {
            if (!$scope.locationGroups[locationGroup].rateInCents && $scope.locationGroups[locationGroup].rateInCents !== 0) {
                toaster.pop('error', "Something went wrong", "Please fill in rate for each location group");
                return false;
            }
        }

        return true;
    }


    function prepareBillingRateRequest(formData) {
        formData.contractType = $scope.contractId;

        var countyRates = [];
        angular.forEach($scope.locationGroups, function (group, key) {
            angular.forEach($scope.counties, function (county, key) {
                if (county.locationGroupId === group.id) {
                    countyRates.push({
                        countyName: county.countyName,
                        state: county.state,
                        groupName: group.name,
                        rateInCents: Number((group.rateInCents * 100).toFixed()),
                        weekendRateInCents: Number(((group.weekendRateInCents ?? group.rateInCents) * 100).toFixed())
                    })
                }
            });
        });

        if ($scope.updateExistingInvoiceRatesData) {
            formData.updateExistingInvoiceRatesData = $scope.updateExistingInvoiceRatesData;
        }

        formData.billingRateCounties = countyRates;
        return formData;
    }


    function prepareAssignedLocationGroups() {
        var locationsIdsMap = $scope.locationGroups.reduce(function (map, obj) {
            map[obj.id] = obj;
            return map;
        }, {});

        var assignedGroups = {};
        $scope.counties.forEach(function (county) {
            if (_.has(locationsIdsMap, county.locationGroupId) && !_.has(assignedGroups, county.locationGroupId)) {
                assignedGroups[county.locationGroupId] = locationsIdsMap[county.locationGroupId];
                assignedGroups[county.locationGroupId].rateInCents = undefined;
                assignedGroups[county.locationGroupId].weekendRateInCents = undefined;

                if (Object.keys($scope.data).length > 0) {
                    const locationGroupBillingRate = $scope.data.rates.find(countyRate => countyRate.groupName === assignedGroups[county.locationGroupId].name);
                    if (locationGroupBillingRate !== undefined) {
                        assignedGroups[county.locationGroupId].rateInCents = locationGroupBillingRate.rateInCents;
                        assignedGroups[county.locationGroupId].weekendRateInCents = locationGroupBillingRate.weekendRateInCents ?? locationGroupBillingRate.rateInCents;
                    }
                }

            }
        });
        $scope.locationGroups = Object.values(assignedGroups);
    }

    function setHistoryTableData() {
        var options = {
            count: 5,
            sorting: { createdAt: "desc" }
        };

        $scope.historyTableData = new NgTableParams(options, {
            counts: [],
            dataset: $scope.rateHistory
        });
    }

    setHistoryTableData();
};