import { LocalDate } from "js-joda";
import moment from "moment";

export const patientMedicalSummary = {
  templateUrl: "admin/views/patient-medical-summary.html",
  bindings: {
    patientId: "<",
    openNewPatientTaskModal: "&",
    patientStartOfCareDate: "<",
    onPatientSocUpdate: "&"
  },
  //! @ngInject
  controller: function (
    $scope,
    $rootScope,
    $uibModal,
    DatabaseApi,
    toaster,
    dateUtils,
    NgTableParams
  ) {
    const initialize = () => {
      $scope.form = {
        startOfCare: null,
      };
      if ($scope.$ctrl.patientStartOfCareDate) {
        $scope.form.startOfCare = moment($scope.$ctrl.patientStartOfCareDate).toDate();
      }
      initCertificationPeriod();
    };

    const initCertificationPeriod = () => {
      let url =
        "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/certification_periods"
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":patientId", $scope.patientId);
      DatabaseApi.get(url)
        .then((res) => {
          $scope.certificationPeriods = res.data.certificationPeriods;
          $scope.certificationPeriods.forEach((row) => {
            const startDateMoment = moment(row.startDate);
            const endDateMoment = moment(row.endDate);
            const nowMoment = moment();
            row.isCurrentPeriod = nowMoment.isBetween(startDateMoment, endDateMoment);
            row.status = {
                label: row.isCompleted ? "Completed" : "Not Completed",
                color: row.isCompleted ? "blue" : "grey",
            };
          });
          initCertificationPeriodsTable($scope.certificationPeriods);
        })
        .catch((err) => {
          toaster.pop(
            "error",
            "Oops.. Something went wrong",
            "Failed to fetch certification periods"
          );
        });
    };

    const initCertificationPeriodsTable = (certificationPeriods) => {
      const options = {
        count: 10,
      };
      $scope.certificationPeriodsTable = new NgTableParams(options, {
        counts: [],
        dataset: certificationPeriods,
      });
    };

    $scope.$watch("form.startOfCare", () => console.log($scope.form.startOfCare));

    $scope.submitStartOfCareForm = () => {
      if (!$scope.form.startOfCare) {
        toaster.pop("error", "Missing start of care date");
        return;
      }

      openCertififcationPeriodModal({
        resolve: {
          allCertificationPeriods: () => $scope.certificationPeriods,
          certificationPeriod: {
            type: "START_OF_CARE",
            startDate: $scope.form.startOfCare,
          },
          patientId: () => parseInt($scope.patientId, 10),
          onSubmitted: () => (res) => {
            handleOnAfterSubmit(res);
          },
          patientStartOfCareDate: () => $scope.$ctrl.patientStartOfCareDate,
        },
      });
    };

    $scope.openNewTaskModalByCertificationPeriod = (certificationPeriod) => {
      let date = LocalDate.parse(certificationPeriod.startDate);
      if (!certificationPeriod.isStartOfCare) {
        date = date.minusMonths(1);
      }
      $scope.$ctrl
        .openNewPatientTaskModal()({
          date,
          certificationPeriod,
          certificationPeriodLocked: true
        })
        .then((res) => {
          if (res && res.taskInstanceId) {
            certificationPeriod.taskInstanceId = res.taskInstanceId;
          }
        });
    };

    $scope.onClickNewPeriod = () => {
      openCertififcationPeriodModal({
        resolve: {
          allCertificationPeriods: () => $scope.certificationPeriods,
          patientId: () => parseInt($scope.patientId, 10),
          patientStartOfCareDate: () => $scope.$ctrl.patientStartOfCareDate,
          onSubmitted: () => (res) => {
            handleOnAfterSubmit(res);
          }
        },
      });
    };

    $scope.editCertificationPeriod = (certificationPeriod) => {
      openCertififcationPeriodModal({
        resolve: {
          allCertificationPeriods: () => $scope.certificationPeriods,
          certificationPeriod: () => certificationPeriod,
          patientId: () => parseInt($scope.patientId, 10),
          onSubmitted: () => (res) => {
            handleOnAfterSubmit(res);
          },
          patientStartOfCareDate: () => $scope.$ctrl.patientStartOfCareDate,
        },
      });
    };

    const openCertififcationPeriodModal = ({ resolve }) => {
      $uibModal.open({
        templateUrl: "admin/views/certification-period-modal.html",
        size: "md",
        controller: "certificationPeriodModal",
        windowClass: "modal center-center",
        resolve: resolve,
      }).result;
    };

    const updatePatientStartOfCareIfNeeded = (updatedSOCStartDate) => {
      if (!$scope.$ctrl.patientStartOfCareDate || moment(updatedSOCStartDate).isAfter(moment($scope.$ctrl.patientStartOfCareDate))) {
        $scope.$ctrl.onPatientSocUpdate({newVal: updatedSOCStartDate});
      }
    };

    const handleOnAfterSubmit = (res) => {
      initCertificationPeriod();
      if (res && res.certificationPeriod.type === 'START_OF_CARE') {
        updatePatientStartOfCareIfNeeded(res.certificationPeriod.startDate);
      }
    };

    this.$onInit = function () {
      $scope.patientId = $scope.$ctrl.patientId;
      initialize();
    };
  },
};
