import { Api } from "./Api";
import { Endpoint } from "./endpoint.service";
import { CertificationPeriod, PatientCertificationPeriodList } from "../messages/certification_period";
import { IPromise, isDefined } from "angular";
import { PatientId } from "../messages/ids";
import { LocalDate } from "js-joda";

//! @ngInject
export class CertificationPeriodService {
    certificationPeriods: CertificationPeriod[] | undefined = undefined;

    constructor(
        private api: Api,
        private endpoint: Endpoint,
        private $rootScope: ng.IRootScopeService
    ) {
    }

    /**
     * Get list of patient certification periods
     */
    getPatientPeriods(patientId: PatientId, params = { refetch: false }): IPromise<CertificationPeriod[]> {
        const path = "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/certification_periods";
        const url = this.endpoint({
            path: path,
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                patientId: patientId
            }
        });

        if (!params.refetch && this.certificationPeriods !== undefined) {
            return Promise.resolve(this.certificationPeriods);
        }

        return this.api
            .get<PatientCertificationPeriodList>(url)
            .then((res) => {
                this.certificationPeriods = res.data.certificationPeriods ?? [];
                return this.certificationPeriods;
            });
    }
}
